import {gsap} from "gsap"
import { ScrollTrigger} from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class TitlesAnimation{
    constructor() {
        this.headings = gsap.utils.toArray('.heading-wrapper')
    }

    init(){
        if(this.headings.length){
            this.animation()
        }
    }

    animation(){
        this.headings.forEach((heading, i)=>{
            let $heading = $(heading)
            let width = 0
            let height = 0

            width = $heading.width() / 2 - 10
            height = $heading.height() / 2 - 20

            let $topRight = $heading.find('.line.top-right')
            let $bottomRight = $heading.find('.line.bottom-right')
            let $topLeft = $heading.find('.line.top-left')
            let $bottomLeft = $heading.find('.line.bottom-left')
            let $title = $heading.find('.heading-wrapper-title')
            let $description = $heading.find('.heading-wrapper-description')

            gsap.set($topRight, {y: height})
            gsap.set($bottomRight, {y: -height})
            gsap.set($topLeft, {y: height})
            gsap.set($bottomLeft, {y: -height})

            gsap.timeline({
                scrollTrigger: {
                    trigger: heading,
                    start: 'top bottom-=40px'
                }
            })
                .to([$topRight, $bottomRight, $topLeft, $bottomLeft], {opacity: 1})
                .fromTo([$topRight, $bottomRight],
                    {x: ()=>{return -($heading.width() / 2 - 10)}},
                    {x: 0},
                    '<0.3'
                )
                .fromTo(
                    [$topLeft, $bottomLeft],
                    {x: ()=>{return ($heading.width() / 2 - 10)}},
                    {x: 0},
                    '<')
                .to([$topRight, $bottomRight, $topLeft, $bottomLeft], {y:0})
                .to($title, {opacity: 1}, '<')
                .to($description, {opacity: 1}, '<0.1')
        })

    }
}

export default TitlesAnimation
