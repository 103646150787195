class ProductTabs{
    constructor() {
        this.$tabsContainer = $('.product-tabs')
        this.$tabs = $('.product-tab')
        this.$images = $('.tab-image')
        this.$descriptions = $('.tab-description')
    }

    init(){
        if(this.$tabsContainer.length){
            this.action()
        }
    }

    action(){
        this.$tabs.click((e)=>{
            let $self = $(e.currentTarget)
            let number = $(e.currentTarget).data('number')

            this.$images.hide()
            this.$descriptions.addClass('hidden')
            this.$tabs.removeClass('selected')
            $self.find('.tab-description').removeClass('hidden')

            $self.addClass('selected')
            $(`img[data-tab-image=${number}]`).show()
        })
    }
}

export default ProductTabs