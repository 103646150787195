import navbar from './components/navbar'
import titlesAnimation from "./components/titlesAnimation"
import bottomTopAnimation from "./components/bottomTopAnimation"
import staggerAnimation from "./components/staggerAnimation"
import tabSection from "./components/tabSection"
import aboutUsHeroAnimation from "./components/aboutUsHeroAnimation"
import newSection from "./components/newSection"
import accordionSection from "./components/accordionSection"
import productTabs from "./components/productTabs";
import scrollToSection from "./components/scrollToSection";
import resources from "./components/resources"
import scrollBar from "./components/scrollBar"
import toggleMenuMobile  from "./components/toggleMenuMobile"
import hover from './components/hover'
import 'sharer.js';
import video from './components/video'
import bioModal from './components/bioModal'
import scrollingSlideshowAnimation from './components/scrollingSlideshowAnimation'

function main() {
    navbar()
    titlesAnimation()
    bottomTopAnimation()
    staggerAnimation()
    tabSection()
    aboutUsHeroAnimation()
    newSection()
    accordionSection()
    productTabs()
    scrollToSection()
    resources()
    scrollBar()
    toggleMenuMobile()
    hover()
    video()
    bioModal()
    scrollingSlideshowAnimation()
}

$(document).ready(main)
