import Tabby from 'tabbyjs'

class TabSection {
    constructor() {
        this.tabs = $('.tabs-section')
    }

    init() {
        if(this.tabs.length){
            this.enableTabs()
        }
    }

    enableTabs() {  
        new Tabby('[data-tabs]');
    }
}

export default TabSection
