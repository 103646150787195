import Accordion from "accordion-js";
import "accordion-js/dist/accordion.min.css";

const init = function () {

    if ($(".accordion-container").length) {
        new Accordion(".accordion-container" , {
            duration: 350,
            collapse : true,
            elementClass: "accordion-item-wrapper",
        });
    }
};

export default init;
