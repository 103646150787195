const init = function () {
    $('#menu-main-nav .menu-item-has-children').hover((e)=>{
        if(e.target.id){
            $('#' + e.target.id + '>.sub-menu>.menu-item').addClass('hovered');
        }
    }, (e)=>{
        // $('.sub-menu>.menu-item').removeClass('hovered');
    })

    $('#menu-main-nav .menu-item-has-children a').hover((e)=>{
        if(e.target.parentElement.id){
            $('#' + e.target.parentElement.id + '>.sub-menu>.menu-item').addClass('hovered');
        }
    }, (e)=>{
        // $('.sub-menu>.menu-item').removeClass('hovered');
    })

    $('#menu-main-nav .menu-item-has-children .sub-menu>.menu-item').hover((e) => {
        if(e.target.parentElement){
            var children = e.target.parentElement.children
            for(var i = 0; i < children.length; ++i){
                $(children[i]).addClass('hovered');
            }
        }

    }, (e) => {

    })

    $('.sub-menu,.menu-item').hover((e)=>{
    }, (e)=>{
        $('.sub-menu>.menu-item').removeClass('hovered');
    })
}

export default init