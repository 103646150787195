class Resources{
    constructor() {
        this.$button = $('.pagination-btn')
        this.$postsContainer = $('.posts')
        this.$search = $('.posts-search')
        this.$paginationButtons = $('.pagination-buttons')
        this.page = this.$paginationButtons.data('page')
        this.pages = this.$paginationButtons.data('pages')
        this.$prev = $('.prev-btn')
        this.$next = $('.next-btn')

        this.$select = $('.js-select')
        this.selectOptionsId = '#posts-options-list'
        this.hiddenClass = 'hidden'
        this.$currentSelect = null
        this.selectValue = '#option-selected'
        this.category = null
        this.tag = null
    }

    init(){
        if(this.$postsContainer.length){
            this.events()
            this.select()
            this.bindFocusOut()
        }
    }

    events(){
        if(this.page === 1)
            this.$prev.prop('disabled',true)

        if(this.pages === this.page)
            this.$next.prop('disabled',true)

        //Pagination
        this.$button.click((e)=>{
            let $self = $(e.currentTarget)
            let type =  $self.data('type')

            if(type === 'next'){
                this.page = ++this.page
                this.$paginationButtons.data('page', this.page)

                if(this.page > 1)
                    this.$prev.prop('disabled', false)

                if(this.pages === this.page)
                    this.$next.prop('disabled',true)

            }

            if(type === 'prev'){
                this.page = --this.page
                this.$paginationButtons.data('page', this.page)

                if(this.page < this.pages)
                    this.$next.prop('disabled', false)

                if(this.page === 1)
                    this.$prev.prop('disabled',true)

            }

            this.$postsContainer.html('')

            $.ajax({
                type: 'post',
                url: ajax_url,
                data: {
                    type: 'html',
                    page: this.page,
                    action: 'getPostsAjax',
                },
                success: (response) => {
                    this.$postsContainer.html(response)
                }
            })


            $('html,body').animate({
                scrollTop: $('#search-bar').offset().top
            }, 500)
        })

        //Search by keyword
        this.$search.keyup((e)=>{
            let $self = $(e.currentTarget)
            let keyword = $self.val()

            this.$postsContainer.html('')


            if(keyword !== ''){
                this.$button.hide()

                $.ajax({
                    type: 'post',
                    url: ajax_url,
                    data: {
                        type: 'html',
                        keyword: keyword,
                        action: 'searchByWord',
                        tag: this.tag,
                        category: this.category
                    },
                    success: (response) => {
                        this.$postsContainer.html(response)
                    }
                })

            }else{

                $.ajax({
                    type: 'post',
                    url: ajax_url,
                    data: {
                        type: 'html',
                        page: 1,
                        action: 'getPostsAjax',
                        tag: this.tag,
                        category: this.category
                    },
                    success: (response) => {
                        this.$postsContainer.html(response)
                    }
                })

                if(this.category === null && this.tag === null){
                    this.$button.show()
                }
            }

        })
    }

    select(){
        this.$select.click(e => {
            this.$currentSelect = $(e.currentTarget)

            this.$currentSelect.find('.select-arrow').toggleClass('rotate')
            this.$options = this.$currentSelect.parent().find(this.selectOptionsId + ' li')

            this.bindOptionSelect()

            let optionList = this.$currentSelect.parent().find(this.selectOptionsId);

            optionList.toggleClass(this.hiddenClass)

        })
    }

    bindOptionSelect() {
        this.$options.click(e => {
            let valueTextSelected = $(e.currentTarget).data('name')
            let valueSelected = $(e.currentTarget).data('tag');
            let type = this.$currentSelect.data('type')

            this.$currentSelect.find('input').val(valueSelected)
            this.$currentSelect.find(this.selectValue).text(valueTextSelected)

            if(type === 'category'){
                this.category = valueSelected

                if(valueSelected === "All"){
                    this.category = null
                }
            }

            if(type === 'tag'){
                this.tag = valueSelected

                if(valueSelected === "All"){
                    this.tag = null
                }
            }

            $.ajax({
                type: 'post',
                url: ajax_url,
                data: {
                    type: 'html',
                    action: 'filterPosts',
                    tag: this.tag,
                    category: this.category
                },
                success: (response) => {
                    this.$postsContainer.html(response)
                }
            })

            this.$button.hide()

            this.closeAll()

            this.$currentSelect.parent().find('#filter-show-all').removeClass('hide')
        })
    }

    bindFocusOut() {
        $(window).click(e => {
            if (this.$currentSelect && !this.$currentSelect.parent().is(e.target) &&
                this.$currentSelect.parent().has(e.target).length === 0 ) {
                this.closeAll()
            }
        });
    }

    closeAll() {
        this.$select
            .parent()
            .find(this.selectOptionsId)
            .addClass(this.hiddenClass)
    }
}

export default Resources