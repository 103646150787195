const init = function () {
    let $button = $('#redirect-section-button')

    if($button.length){
        let section = $button.data('section')


        $button.click((e)=>{
            e.preventDefault()

            $('html,body').animate({
                scrollTop: $('#' + section).offset().top
            }, 500)
        })
    }
}

export default init