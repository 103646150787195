import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

const init = function () {
    let $tableWrapper = $(".comparison-table-wrapper");
    
    if ($tableWrapper.length) {
        const ps = new PerfectScrollbar('.comparison-table-wrapper', {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20,
            suppressScrollY: true,
            scrollingThreshold: 0
        });
    }
}

export default init