import {gsap} from "gsap"
import { ScrollTrigger} from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class StaggerAnimation{
    constructor() {
        this.containers = gsap.utils.toArray('.stagger-animation')
    }

    init(){
        if(this.containers.length){
            this.animation()
        }
    }

    animation(){
        this.containers.forEach((container, i)=>{
            gsap.timeline({
                delay: i * 0.15,
                scrollTrigger: {
                    trigger: container,
                    start: 'top bottom-=40px'
                }
            })
                .to(container, {opacity: 1, stagger: .5,})
                .from(container, {y: '50px', stagger: .5}, '<')
        })
    }
}

export default StaggerAnimation