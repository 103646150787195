class NavBar {
    constructor(element) {
        this.element = element
        this.scrollPosition = window.scrollY
        this.savedScrollPosition = window.scrollY
        this.stateClass = 'nav-at-top'
        this.ticking = false
        this.showMobileMenu = false
        this.offset = 5 //px
        this.hamburguerMenu = $(this.element).find('.hamburger-container')[0]
        this.hamburguerMenuLines = $(this.element).find('.hamburger-container .menu')[0]
        this.openMobileMenuClass = 'open'
        this.navbarLogoMobile = $('.navbar-logo.mobile')[0]
    }

    init() {
        this.element.classList.add(this.stateClass)
        window.addEventListener('scroll', () => this.updateScrollPosition(), false)

        if (this.hamburguerMenu) {
            this.hamburguerMenuLines.addEventListener('click', () => this.handleClickHamburgerMenu())
        }
    }

    handleClickHamburgerMenu() {
        if (!this.showMobileMenu) {
            this.element.classList.add(this.openMobileMenuClass)
            this.hamburguerMenu.classList.add('active')
            this.navbarLogoMobile.classList.add('active')
            this.toggleMobileNav()
        } else {
            this.element.classList.remove(this.openMobileMenuClass)
            this.hamburguerMenu.classList.remove('active')
            this.navbarLogoMobile.classList.remove('active')
            this.toggleMobileNav()
        }
    }

    updateScrollPosition() {
        this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop
        this.ticking = false
        this.element.classList.remove(this.stateClass)
        this.stateClass = this.setStateClass()
        this.element.classList.add(this.stateClass)
        this.savedScrollPosition = this.setSavedScrollPosition()
    }

    toggleMobileNav() {
        this.showMobileMenu = !this.showMobileMenu
        if (this.showMobileMenu) {
            document.body.classList.add('no-scroll')
        } else {
            document.body.classList.remove('no-scroll')
        }
    }

    setStateClass() {
        if (this.scrollPosition === 0) {
            return 'nav-at-top'
        }

        if (this.stateClass === 'nav-hide' &&
            this.scrollPosition <= this.savedScrollPosition - this.offset
        ) {
            return 'nav-show'
        }

        if (
            this.stateClass !== 'nav-hide' &&
            this.scrollPosition >= this.savedScrollPosition + this.offset
        ) {
            return 'nav-show' // Keeping it always showing for now
        }

        return this.stateClass
    }

    setSavedScrollPosition() {
        if (this.scrollPosition < 5) {
            return this.scrollPosition
        }

        if (this.scrollPosition > this.savedScrollPosition && this.stateClass !== 'nav-show') {
            return this.scrollPosition
        }

        if (this.scrollPosition < this.savedScrollPosition && this.stateClass !== 'nav-hide') {
            return this.scrollPosition
        }

        return this.savedScrollPosition
    }
}

const initialize = () => {
    let navBar = new NavBar(document.querySelector('.navbar')).init()
}

export default initialize
