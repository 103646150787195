import {gsap} from "gsap"
import { ScrollTrigger} from "gsap/ScrollTrigger"
import Foundation from 'foundation-sites';

gsap.registerPlugin(ScrollTrigger)

class ScrollingSlideshowAnimation{
    constructor() {
        this.$slides = gsap.utils.toArray('.scrolling-slide-container')
        this.$slidesContainer = $('.scrolling-slideshow-slides')
        this.timeline = gsap.timeline()
        this.scroll = gsap.timeline()

    }

    init(){
        if(this.$slides.length){
            if(Foundation.MediaQuery.is('large up')){
                gsap.set(this.$slides, {xPercent: 100})
                this.animation()
            }
        }
    }

    animation(){

        this.timeline = gsap.timeline({
            scrollTrigger:{
                trigger: this.$slidesContainer,
                start: 'top+=80px top+=350px',
                end: () => '+=' + ( $(this.$slides[0]).width() * this.$slides.length),
                scrub: 0.1,
                invalidateOnRefresh: true
            }
        }).to(this.$slides, {
            x: () => {
                let slideWidth = $(this.$slides[0]).width()
                let slidesWidth = slideWidth * this.$slides.length
                return -(slidesWidth + 380)
            },
            ease: 'none'
        })


        this.scroll = gsap.timeline({
            scrollTrigger: {
                trigger: this.$slidesContainer,
                pin: true,
                scrub: 0.5,
                start: 'top top+=60px',
                end: ()=> '+=' + ($(this.$slides[0]).width() * this.$slides.length),
                invalidateOnRefresh: true
            }
        })

    }
}

export default ScrollingSlideshowAnimation