import {gsap} from "gsap"
import { ScrollTrigger} from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class BottomTopAnimation{
    constructor() {
        this.texts = gsap.utils.toArray('.bottom-top-animation')
    }

    init(){
        if(this.texts.length){
            this.animation()
        }
    }

    animation(){
        this.texts.forEach((text, i)=>{
            gsap.timeline({
                scrollTrigger: {
                    trigger: text,
                    start: 'top bottom-=40px',
                }
            })
                .to(text, {opacity: 1})
                .from(text, {y: '50px'}, '<')
        })
    }

}

export default BottomTopAnimation