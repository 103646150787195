import Swiper, { Navigation } from "swiper";
Swiper.use([Navigation]);

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

const init = function () {
    let $slider = $(".swiper");
    
    if ($slider.length) {
        new Swiper(".swiper", {
            observer: true,
            slidesPerView: 1,
            spaceBetween: 20,
            observeParents: true,
            direction: "horizontal",
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },

                1200: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },

                768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                },
            },
        });
    }
};

export default init;
